import React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./header.scss"

const Header = () => <header>
    <Link to="/" id="header-logo-link" >
      <StaticImage
        src="../images/bw_logo.png"
        alt="bandwagonfanclub logo"
      />
    </Link>
    <nav id="header-nav">
        <ul>
            <li><Link id="hiring-desktop" activeClassName="a-active" to="https://bandwagon.breezy.hr/">We're&nbsp;Hiring!</Link></li>
            <li><Link activeClassName="a-active" to="/">Home</Link></li>
            <li><Link activeClassName="a-active" to="/contact">Contact</Link></li>
            <li><Link activeClassName="a-active" to="/team">Team</Link></li>
        </ul>
    </nav>
</header>;

export default Header;
