import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"

import { Helmet } from 'react-helmet'

import './bandwagon-dot-com.scss';
import '../styles/fonts/fonts.css'

const BandwagonDotCom = ({ children, className = '', fillPage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js"></script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main
        className={`${className} ${
          fillPage ? "flex-grow flex justify-center items-center" : ""
        }`}
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

BandwagonDotCom.propTypes = {
  children: PropTypes.node.isRequired,
  fillPage: PropTypes.bool,
}

export default BandwagonDotCom
