import React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaInstagram
} from "react-icons/fa"

import "./footer.scss"

const Footer = () => <footer class="block">
    <Link to="/" id="footer-logo-link" >
      <StaticImage
        src="../images/bw_logo_white.png"
        alt="bandwagonfanclub logo"
      />
    </Link>
    <hr/>
    <nav id="footer-nav">
        <ul>
            <li><Link to="/">Home</Link></li> |
            <li><Link to="/contact">Contact</Link></li> |
            <li><Link to="/team">Team</Link></li> |
            <li><Link to="/privacy">Privacy Policy</Link></li>
        </ul>
    </nav>
    <div id="social-links">
        <a aria-label="Bandwagon on Facebook" href="https://www.facebook.com/TheBandwagonFanClub"><FaFacebookF /></a>
        <a aria-label="Bandwagon on Twitter" href="https://twitter.com/builtxbandwagon"><FaTwitter /></a>
        <a aria-label="Bandwagon on Instagram" href="https://www.instagram.com/builtxbandwagon/"><FaInstagram /></a>
        <a aria-label="Bandwagon on YouTube" href="http://www.youtube.com/c/BandwagonFanClub"><FaYoutube /></a>
    </div>
</footer>;

export default Footer;
