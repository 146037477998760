import React from "react"

import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai"

import './mobile-menu.scss';

const MobileMenu = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const childArray = React.Children.toArray(children);
    const currentSelection = childArray.shift();

    const actionIcon = isOpen ?
            <AiOutlineUpCircle class="mobile-menu-action-icon" />
            : <AiOutlineDownCircle class="mobile-menu-action-icon" />;

    return <nav id="mobile-menu">
        <ul>
            <li class="mobile-menu-selected-item">
                <button onClick={() => setIsOpen(!isOpen)}>
                    {
                        React.cloneElement(currentSelection, {}, [
                            <span>
                                {currentSelection.props.children}
                            </span>,
                            actionIcon
                        ])
                    }
                </button>
            </li>
            {
                isOpen && childArray.map(c =>
                    <li>{c}</li>
                )
            }
        </ul>
    </nav>;
};

export default MobileMenu;
